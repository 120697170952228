import React from "react"
import { CenterAligned, UnstyledButton } from "@opensea/ui-kit"
import { useFragment } from "react-relay"
import { RecordSourceProxy } from "relay-runtime"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Image } from "@/design-system/Image"
import { Modal } from "@/design-system/Modal"
import { usePrimaryDropContext } from "@/features/primary-drops/hooks/usePrimaryDropContext"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { ReminderConfirmationModalContent_collection$key } from "@/lib/graphql/__generated__/ReminderConfirmationModalContent_collection.graphql"
import { ReminderConfirmationModalContent_user$key } from "@/lib/graphql/__generated__/ReminderConfirmationModalContent_user.graphql"
import { ReminderConfirmationModalContentMutation } from "@/lib/graphql/__generated__/ReminderConfirmationModalContentMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { PLACEHOLDER_IMAGE } from "../../../../../constants"
import { useCalendars } from "../../AddToCalendar"
import { ResponsiveText } from "../../ResponsiveText"

type ReminderConfirmationModalContentProps = {
  collectionDataKey: ReminderConfirmationModalContent_collection$key | null
  userDataKey: ReminderConfirmationModalContent_user$key | null
  onClose: () => void
}

export const ReminderConfirmationModalContent = ({
  collectionDataKey,
  userDataKey,
  onClose,
}: ReminderConfirmationModalContentProps) => {
  const t = useTranslate("drop")
  const collection =
    useFragment<ReminderConfirmationModalContent_collection$key>(
      graphql`
        fragment ReminderConfirmationModalContent_collection on CollectionType {
          ...AddToCalendar_collection
          name
          imageUrl
          dropv2 {
            stages {
              relayId
              ...AddToCalendar_stage
              reminderEnabled
            }
          }
        }
      `,
      collectionDataKey,
    )

  const { mutate } = useGraphQL()
  const { attempt, showSuccessMessage } = useToasts()

  const user = useFragment<ReminderConfirmationModalContent_user$key>(
    graphql`
      fragment ReminderConfirmationModalContent_user on UserType {
        email
        emailVerified
      }
    `,
    userDataKey,
  )

  const { collectionSlug } = usePrimaryDropContext()

  const removeReminder = async () => {
    await attempt(async () => {
      await mutate<ReminderConfirmationModalContentMutation>(
        graphql`
          mutation ReminderConfirmationModalContentMutation(
            $input: DropReminderMutationInput!
          ) {
            users {
              dropReminder(input: $input) {
                ...ReminderConfirmationModalContent_user
              }
            }
          }
        `,
        {
          input: {
            collection: collectionSlug,
            dropStages: [],
            email: user?.email,
          },
        },
        {
          updater: (store: RecordSourceProxy) => {
            for (const stage of collection?.dropv2?.stages || []) {
              const storedStage = store.get(stage.relayId)
              storedStage?.setValue(false, "reminderEnabled")
            }
          },
        },
      )
    })
    showSuccessMessage(
      t(
        "reminderConfirmationModal.unsuscribed",
        "Unsubscribed from reminders.",
      ),
    )
    onClose()
  }

  const calendars = useCalendars()

  const unsuscribeLink = (
    <Link onClick={removeReminder}>
      {t("reminderConfirmationModal.unsubscribeButton", "Unsubscribe")}
    </Link>
  )

  return (
    <Modal.Body>
      <CenterAligned className="px-0 pb-6 lg:px-6">
        <Block
          className="relative rounded-xl"
          height="200px"
          marginBottom="32px"
          marginTop="92px"
          overflow="hidden"
          width="200px"
        >
          <Image
            alt={collection?.name}
            layout="fill"
            src={collection?.imageUrl ?? PLACEHOLDER_IMAGE}
            width={200}
          />
        </Block>
        <ResponsiveText.Heading size="mediumOnModal">
          {user?.emailVerified
            ? t(
                "reminderConfirmationModal.titleHavingEmailVerified",
                "You're signed up for email reminders",
              )
            : t(
                "reminderConfirmationModal.titleHavingEmailNoVerified",
                "Verify your email address",
              )}
        </ResponsiveText.Heading>
        <Block marginTop="12px">
          <ResponsiveText.Body asChild className="text-center" size="medium">
            <p>
              {user?.emailVerified
                ? t(
                    "reminderConfirmationModal.descriptionHavingEmailVerified",
                    "A confirmation has been sent to {{userEmail}}. Email reminders will be sent to this address. {{unsuscribeLink}}",
                    {
                      userEmail: user.email,
                      unsuscribeLink,
                    },
                  )
                : t(
                    "reminderConfirmationModal.descriptionHavingEmailNoVerified",
                    "A confirmation email has been sent to {{userEmail}}. Once you verify your email address, you’ll start getting reminders. {{unsuscribeLink}}",
                    {
                      userEmail: user?.email,
                      unsuscribeLink,
                    },
                  )}
            </p>
          </ResponsiveText.Body>
        </Block>
        <Block marginTop="24px">
          <ResponsiveText.Body
            asChild
            className="text-center"
            size="medium"
            weight="semibold"
          >
            <p>
              {t(
                "reminderConfirmationModal.addToCalendarButton",
                "Add to calendar",
              )}
            </p>
          </ResponsiveText.Body>
          <CalendarsContainer>
            {calendars.map(calendar => (
              <Flex flex={1} key={calendar.name}>
                <UnstyledButton
                  onClick={() => {
                    const currentStage = collection?.dropv2?.stages.find(
                      stage => stage.reminderEnabled,
                    )
                    if (!currentStage || !collection) {
                      return
                    }
                    calendar.add({ collection, stage: currentStage })
                  }}
                >
                  {calendar.icon}
                </UnstyledButton>
              </Flex>
            ))}
          </CalendarsContainer>
        </Block>
      </CenterAligned>
    </Modal.Body>
  )
}

const CalendarsContainer = styled(Flex)`
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
`
