/**
 * @generated SignedSource<<4673b7b951c77283a8f6c1b4aed5341d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useUpcomingStages_data$data = {
  readonly endTime: string;
  readonly startTime: string;
  readonly " $fragmentType": "useUpcomingStages_data";
};
export type useUpcomingStages_data$key = {
  readonly " $data"?: useUpcomingStages_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useUpcomingStages_data">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useUpcomingStages_data"
};

(node as any).hash = "ac52c5f6be664d6ece718dff3ddfcd7a";

export default node;
