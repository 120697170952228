import React, { useEffect, useState } from "react"
import {
  UnstyledButton,
  RadioGroup,
  VerticalAligned,
  FlexColumn,
} from "@opensea/ui-kit"
import { parseJSON } from "date-fns"
import {
  CalendarOptions,
  ICalendar,
  YahooCalendar,
  GoogleCalendar,
  OutlookCalendar,
} from "datebook"
import { graphql, useFragment } from "react-relay"
import { _FragmentRefs } from "relay-runtime"
import styled from "styled-components"
import { AppleLogo } from "@/components/svgs/AppleLogo.react"
import { GoogleLogo } from "@/components/svgs/GoogleLogo.react"
import { OutlookLogo } from "@/components/svgs/OutlookLogo.react"
import { YahooLogo } from "@/components/svgs/YahooLogo.react"
import { Flex } from "@/design-system/Flex"
import { Modal, UncontrolledModalProps } from "@/design-system/Modal"
import { usePrimaryDropContext } from "@/features/primary-drops/hooks/usePrimaryDropContext"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackOpenCalendar,
  trackSetCalendar,
} from "@/lib/analytics/events/primaryDropsV2Events"
import { AddToCalendar_collection$data } from "@/lib/graphql/__generated__/AddToCalendar_collection.graphql"
import { AddToCalendar_data$key } from "@/lib/graphql/__generated__/AddToCalendar_data.graphql"
import { AddToCalendar_stage$data } from "@/lib/graphql/__generated__/AddToCalendar_stage.graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { getCollectionDropUrl } from "@/lib/helpers/collection"
import { useDropStages } from "../../hooks/useDropStages"
import { ResponsiveText } from "../ResponsiveText"
import { StageInfo } from "../StageInfo"

export const AddToCalendar = ({
  trigger,
  dataKey,
}: {
  trigger: UncontrolledModalProps["trigger"]
  dataKey: AddToCalendar_data$key
}) => {
  const t = useTranslate("drop")
  const data = useFragment<AddToCalendar_data$key>(
    graphql`
      fragment AddToCalendar_data on CollectionType {
        slug
        ...AddToCalendar_collection
        dropv2 {
          stages {
            ...StageInfo_data
            ...AddToCalendar_stage
            ...useDropStages
            endTime
            startTime
            relayId
          }
        }
      }
    `,
    dataKey,
  )
  const { dropv2: drop } = data
  const [stageId, setStageId] = useState<string>()
  const { ctaStage } = useDropStages(drop?.stages ?? [])

  const { isStageActive, isPastStage } = usePrimaryDropContext()

  useEffect(() => {
    if (
      ctaStage?.relayId &&
      !isStageActive({
        startTime: ctaStage.startTime,
        endTime: ctaStage.endTime,
      })
    ) {
      setStageId(ctaStage.relayId)
    }
  }, [ctaStage, isStageActive])

  const calendars = useCalendars()

  if (!drop || !ctaStage) {
    return null
  }

  return (
    <Modal
      size="medium"
      trigger={open =>
        trigger(() => {
          trackOpenCalendar({ collectionSlug: data.slug })
          open()
        })
      }
    >
      <VerticalAligned>
        <ModalBody>
          <FlexColumn className="mb-8 mt-[62px] px-0 text-center lg:px-6">
            <ResponsiveText.Heading size="mediumOnModal">
              {t("addToCalendar.title", "Add a reminder to your calendar")}
            </ResponsiveText.Heading>
          </FlexColumn>
          <FlexColumn className="mb-8">
            <RadioGroup defaultValue={stageId} onValueChange={setStageId}>
              {drop.stages.map(stage => (
                <Flex className="min-w-0" key={stage.relayId}>
                  <RadioGroup.Item
                    disabled={
                      isPastStage({ endTime: stage.endTime }) ||
                      isStageActive({
                        startTime: stage.startTime,
                        endTime: stage.endTime,
                      })
                    }
                    id={stage.relayId}
                    value={stage.relayId}
                  />
                  <RadioButtonLabel htmlFor={stage.relayId}>
                    <StageInfo showEligibility={false} stageKey={stage} />
                  </RadioButtonLabel>
                </Flex>
              ))}
            </RadioGroup>
          </FlexColumn>
          <ResponsiveText.Body size="medium" weight="semibold">
            {t("addToCalendar.subtitle", "Choose calendar")}
          </ResponsiveText.Body>
          <FlexColumn className="mb-2 mt-4">
            {calendars.map(calendar => {
              return (
                <Flex
                  alignItems="center"
                  height="48px"
                  justifyContent="space-between"
                  key={calendar.name}
                >
                  <Flex>
                    {calendar.icon}
                    <ResponsiveText.Body className="ml-4" size="medium">
                      {calendar.name}
                    </ResponsiveText.Body>
                  </Flex>
                  <UnstyledButton
                    onClick={() => {
                      const currentStage = drop.stages.find(
                        ({ relayId }) => relayId === stageId,
                      )
                      if (!currentStage) {
                        return
                      }
                      trackSetCalendar({
                        stage: currentStage.relayId,
                        collectionSlug: data.slug,
                      })
                      calendar.add({ collection: data, stage: currentStage })
                    }}
                  >
                    <AddLink size="medium" weight="semibold">
                      {t("addToCalendar.callToAction", "Add")}
                    </AddLink>
                  </UnstyledButton>
                </Flex>
              )
            })}
          </FlexColumn>
        </ModalBody>
      </VerticalAligned>
    </Modal>
  )
}

const readCollectionDataToCreateEvent =
  inlineFragmentize<AddToCalendar_collection$data>(
    graphql`
      fragment AddToCalendar_collection on CollectionType @inline {
        ...collection_url
        dropv2 {
          name
        }
      }
    `,
    p => p,
  )

const readStageDataToCreateEvent = inlineFragmentize<AddToCalendar_stage$data>(
  graphql`
    fragment AddToCalendar_stage on DropStageV2Type @inline {
      label
      startTime
      endTime
    }
  `,
  p => p,
)

type CreateEvenfromStageParams = {
  collection: _FragmentRefs<"AddToCalendar_collection">
  stage: _FragmentRefs<"AddToCalendar_stage">
}

const useCreateEvenFromStage = () => {
  const t = useTranslate("drop")
  return ({
    collection,
    stage,
  }: CreateEvenfromStageParams): CalendarOptions => {
    const { dropv2: drop, ...restCollection } =
      readCollectionDataToCreateEvent(collection)
    const collectionName =
      drop?.name || t("addToCalendar.event.dropDefaultName", "Unnamed drop")
    const {
      label: stageLabel,
      startTime,
      endTime,
    } = readStageDataToCreateEvent(stage)
    const dropUrl = `https://${window.location.host}${getCollectionDropUrl(
      restCollection,
    )}`
    const event: CalendarOptions = {
      title: t(
        "addToCalendar.event.title",
        "{{collectionName}} {{stageLabel}} starts now",
        { collectionName, stageLabel },
        { forceString: true },
      ),
      description: t(
        "addToCalendar.event.description",
        "Don't miss out - mint now at <a href='{{dropUrl}}'>{{dropUrl}}</a><br><br>",
        { dropUrl },
        { forceString: true },
      ),
      start: parseJSON(startTime),
    }
    if (endTime) {
      event["end"] = parseJSON(endTime)
    }
    return event
  }
}

export const useCalendars = () => {
  const createEvenfromStage = useCreateEvenFromStage()
  return [
    {
      icon: <GoogleLogo />,
      name: "Google Calendar",
      add: (params: CreateEvenfromStageParams) => {
        const event = createEvenfromStage(params)
        const calendar = new GoogleCalendar(event)
        window.open(calendar.render(), "_blank")
      },
    },
    {
      icon: <AppleLogo />,
      name: "iCal",
      add: (params: CreateEvenfromStageParams) => {
        const event = createEvenfromStage(params)
        const calendar = new ICalendar(event)
        calendar.download("OpenSeaDropReminder.ics")
      },
    },
    {
      icon: <OutlookLogo />,
      name: "Outlook",
      add: (params: CreateEvenfromStageParams) => {
        const event = createEvenfromStage(params)
        const calendar = new OutlookCalendar(event)
        window.open(calendar.render(), "_blank")
      },
    },
    {
      icon: <YahooLogo />,
      name: "Yahoo Mail",
      add: (params: CreateEvenfromStageParams) => {
        const event = createEvenfromStage(params)
        const calendar = new YahooCalendar(event)
        window.open(calendar.render(), "_blank")
      },
    },
  ]
}

const AddLink = styled(ResponsiveText.Body)`
  cursor: pointer;
  color: ${props => props.theme.colors.seaBlue};
  :hover {
    color: ${props => props.theme.colors.darkSeaBlue};
  }
`

const RadioButtonLabel = styled.label`
  cursor: pointer;
`

const ModalBody = styled(Modal.Body)`
  width: 100%;
`
