import React, { useEffect, Suspense } from "react"
import { Input, Skeleton } from "@opensea/ui-kit"
import { Controller, useFormContext } from "react-hook-form"
import { useLazyLoadQuery } from "react-relay"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { FormControl } from "@/design-system/FormControl"
import { useTranslate } from "@/hooks/useTranslate"
import { EmailInputReminderQuery } from "@/lib/graphql/__generated__/EmailInputReminderQuery.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { EMAIL_VALIDATION_RULE } from "@/lib/helpers/validation"

const LazyLoadedEmailInput = () => {
  const t = useTranslate("drop")
  const { formState, control, setValue } = useFormContext()
  const connectedAddress = useConnectedAddress()

  const data = useLazyLoadQuery<EmailInputReminderQuery>(
    graphql`
      query EmailInputReminderQuery($identity: AddressScalar!) {
        getAccount(address: $identity) {
          user {
            email
          }
        }
      }
    `,
    {
      identity: connectedAddress ?? "",
    },
    {
      fetchPolicy: "store-and-network",
    },
  )

  const email = data.getAccount.user?.email

  useEffect(() => {
    if (email) {
      setValue("email", email)
    }
  }, [email, setValue])

  return (
    <FormControl
      error={formState.errors.email?.message as string}
      hideLabel
      label={t("reminderEmailInput.label", "Email address")}
    >
      <Controller
        control={control}
        defaultValue={email ?? ""}
        name="email"
        render={({ field }) => (
          <Input
            disabled={!!email}
            id={field.name}
            name={field.name}
            placeholder={t(
              "reminderEmailInput.placeholder",
              "Enter email address",
            )}
            type="email"
            value={field.value}
            onChange={field.onChange}
          />
        )}
        rules={{
          required: t(
            "reminderEmailInput.error",
            "Enter a valid email address.",
          ),
          pattern: EMAIL_VALIDATION_RULE,
        }}
      />
    </FormControl>
  )
}

export const EmailInput = () => {
  return (
    <Suspense fallback={<Skeleton.Row className="h-12 w-full" />}>
      <LazyLoadedEmailInput />
    </Suspense>
  )
}
