/**
 * @generated SignedSource<<4a9025ab4a8023c4d19121100f3edc9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReminderConfirmationModalContent_collection$data = {
  readonly dropv2: {
    readonly stages: ReadonlyArray<{
      readonly relayId: string;
      readonly reminderEnabled: boolean;
      readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_stage">;
    }>;
  } | null;
  readonly imageUrl: string | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_collection">;
  readonly " $fragmentType": "ReminderConfirmationModalContent_collection";
};
export type ReminderConfirmationModalContent_collection$key = {
  readonly " $data"?: ReminderConfirmationModalContent_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReminderConfirmationModalContent_collection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReminderConfirmationModalContent_collection",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "AddToCalendar_collection",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "collection_url",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCategory",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "dropv2",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayId",
              "storageKey": null
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "AddToCalendar_stage",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "startTime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endTime",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reminderEnabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "59e970b373ee0abfa56f8a01d76ffae8";

export default node;
