/**
 * @generated SignedSource<<b8159c9ea13f2f065378244b15f62d12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useShouldShowReminder_data$data = {
  readonly dropv2: {
    readonly __typename: string;
    readonly stages: ReadonlyArray<{
      readonly startTime: string;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"useDropState_data">;
  readonly " $fragmentType": "useShouldShowReminder_data";
};
export type useShouldShowReminder_data$key = {
  readonly " $data"?: useShouldShowReminder_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"useShouldShowReminder_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useShouldShowReminder_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useDropState_data"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};

(node as any).hash = "59ee9bbad6c54988c5618d1e9f36ed27";

export default node;
