import { parseJSON, sub, isBefore } from "date-fns"
import { graphql, useFragment } from "react-relay"
import { useShouldShowReminder_data$key } from "@/lib/graphql/__generated__/useShouldShowReminder_data.graphql"
import { isDrop1155 } from "../utils/dropTypeTests"
import { DROP_STATES, useDropState } from "./useDropState"

const REMINDER_BUTTON_THRESHOLD_MINUTES = 60

export const useShouldShowReminder = (
  dropKey: useShouldShowReminder_data$key | null,
) => {
  const data = useFragment<useShouldShowReminder_data$key>(
    graphql`
      fragment useShouldShowReminder_data on CollectionType {
        ...useDropState_data
        dropv2 {
          __typename
          stages {
            startTime
          }
        }
      }
    `,
    dropKey,
  )
  const dropState = useDropState(data)
  if (!data?.dropv2) {
    return false
  }
  return (
    dropState === DROP_STATES.UPCOMING &&
    (isBefore(
      Date.now(),
      sub(
        parseJSON(data.dropv2.stages[data.dropv2.stages.length - 1].startTime),
        {
          minutes: REMINDER_BUTTON_THRESHOLD_MINUTES,
        },
      ),
    ) ||
      isDrop1155(data.dropv2.__typename))
  )
}
