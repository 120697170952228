/**
 * @generated SignedSource<<616eb5f35630125e2d72a63cc857afa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReminderConfirmationModalContent_user$data = {
  readonly email: string | null;
  readonly emailVerified: boolean | null;
  readonly " $fragmentType": "ReminderConfirmationModalContent_user";
};
export type ReminderConfirmationModalContent_user$key = {
  readonly " $data"?: ReminderConfirmationModalContent_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReminderConfirmationModalContent_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReminderConfirmationModalContent_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailVerified",
      "storageKey": null
    }
  ],
  "type": "UserType",
  "abstractKey": null
};

(node as any).hash = "43bb9bb7c8e89d0230267cfa8a212bee";

export default node;
