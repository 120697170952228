import React from "react"
import { useIsLessThanLg } from "@opensea/ui-kit"
import { Button, ButtonProps } from "@/design-system/Button"
import { UnreachableCaseError } from "@/lib/helpers/type"

export type ResponsiveButtonProps = ButtonProps

export const ResponsiveButton = ({
  size = "large",
  ...props
}: ResponsiveButtonProps) => {
  switch (size) {
    case "small":
      return <ResponsiveButtonSmall {...props} />
    case "large":
      return <Button {...props} />
    default:
      throw new UnreachableCaseError(size)
  }
}

const ResponsiveButtonSmall = ({ children, ...rest }: ButtonProps) => {
  const isSmall = useIsLessThanLg()
  return (
    <Button {...rest} size={isSmall ? "small" : "large"}>
      {children}
    </Button>
  )
}
