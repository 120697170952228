/**
 * @generated SignedSource<<4ae5a5ea61a613f63fd3939497958330>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DropStageStageType = "PRESALE" | "PUBLIC" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StageInfo_data$data = {
  readonly allowlistSet: boolean;
  readonly endTime: string;
  readonly isEligible: boolean;
  readonly label: string;
  readonly stageType: DropStageStageType;
  readonly startTime: string;
  readonly " $fragmentType": "StageInfo_data";
};
export type StageInfo_data$key = {
  readonly " $data"?: StageInfo_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"StageInfo_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StageInfo_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowlistSet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isEligible",
      "storageKey": null
    }
  ],
  "type": "DropStageV2Type",
  "abstractKey": "__isDropStageV2Type"
};

(node as any).hash = "1e3b23a78eeaa96629f8b13bc69bc3a8";

export default node;
