import React from "react"
import styled from "styled-components"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  className?: string
  height?: number
  width?: number
}

export const OutlookLogo = ({ height = 24, width = 24, ...rest }: Props) => {
  return (
    <StyledSvg
      height={height}
      viewBox="0 0 16.376 16.376"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.376,6.059c0-0.3-0.215-0.544-0.478-0.544h-4.697v1.087h3.717L12.48,8.611l-1.279-1.048v1.379
			l1.003,0.813c0.163,0.132,0.392,0.132,0.555-0.001l2.661-2.157v2.873h-4.219v1.087h4.697c0.263,0,0.478-0.243,0.478-0.544V6.541
			c0-0.013-0.001-0.025-0.002-0.038c0.001-0.013,0.002-0.026,0.002-0.038C16.376,6.465,16.376,6.059,16.376,6.059z"
      />
      <path
        d="M5.115,10.433c0.686,0,1.126-0.904,1.126-2.253c0-1.119-0.325-2.253-1.126-2.253
			c-0.847,0-1.16,1.149-1.16,2.253C3.955,9.467,4.361,10.433,5.115,10.433z"
      />
      <path
        d="M0,14.992l10.195,0.748V0.637L0,1.534V14.992z M5.162,4.272c1.741,0,2.902,1.578,2.902,3.831
			c0,2.743-1.475,4-2.995,4c-1.66,0-2.937-1.44-2.937-3.861C2.133,5.819,3.34,4.272,5.162,4.272z"
      />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  ${({ theme }) =>
    themeVariant({
      variants: {
        light: {
          fill: theme.colors.blackPearl,
        },
        dark: {
          fill: theme.colors.white,
        },
      },
    })}
`
