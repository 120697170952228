import type { _FragmentRefs } from "relay-runtime"
import { useUpcomingStages_data$data } from "@/lib/graphql/__generated__/useUpcomingStages_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { inlineFragmentize } from "@/lib/graphql/inline"
import { usePrimaryDropContext } from "./usePrimaryDropContext"

const readStagesDataForUpcomingStages =
  inlineFragmentize<useUpcomingStages_data$data>(
    graphql`
      fragment useUpcomingStages_data on DropStageV2Type @inline {
        startTime
        endTime
      }
    `,
    p => p,
  )

export const useUpcomingStages = <
  Stage extends _FragmentRefs<"useUpcomingStages_data">,
>(
  stages: readonly Stage[] | undefined,
): readonly Stage[] => {
  const { isPastStage, isStageActive } = usePrimaryDropContext()
  return (stages || []).filter(stage => {
    const stageData = readStagesDataForUpcomingStages(stage)
    return (
      !isPastStage({
        endTime: stageData.endTime,
      }) &&
      !isStageActive({
        startTime: stageData.startTime,
        endTime: stageData.endTime,
      })
    )
  })
}
