import React, { ReactNode } from "react"
import { SpaceBetween, classNames } from "@opensea/ui-kit"
import { getYear, isThisYear, parseJSON } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import { useFragment, graphql } from "react-relay"
import styled from "styled-components"
import { Block } from "@/design-system/Block"
import { isStageActive } from "@/features/primary-drops/containers/PrimaryDropProvider"
import { usePrimaryDropContext } from "@/features/primary-drops/hooks/usePrimaryDropContext"
import { useTranslate } from "@/hooks/useTranslate"
import { StageInfo_data$key } from "@/lib/graphql/__generated__/StageInfo_data.graphql"
import { ResponsiveText } from "../ResponsiveText"

type StageInfoProps = {
  stageKey: StageInfo_data$key
  children?: ReactNode
  showEligibility?: boolean
  className?: string
  isMockDrop?: boolean
}

export const StageInfo = ({
  stageKey,
  children,
  showEligibility = true,
  isMockDrop = false,
  className,
}: StageInfoProps) => {
  const t = useTranslate("drop")
  const stage = useFragment<StageInfo_data$key>(
    graphql`
      fragment StageInfo_data on DropStageV2Type {
        endTime
        label
        startTime
        allowlistSet
        stageType
        isEligible
      }
    `,
    stageKey,
  )
  const { isEligible } = stage

  const { isPastStage, mintedOut, editMode, isOpenEdition } =
    usePrimaryDropContext()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const showStartAndEndTime = isOpenEdition || isMockDrop
  const formatTime = (time: string) => {
    const date = parseJSON(time)
    if (getYear(date) !== getYear(new Date())) {
      return formatInTimeZone(date, timeZone, "MMMM d, yyyy 'at' h:mm aa zzz")
    }
    return formatInTimeZone(date, timeZone, "MMMM d 'at' h:mm aa zzz")
  }

  return (
    <SpaceBetween className={className}>
      <Block>
        <ScheduleRowText size="medium" weight="semibold">
          {stage.label}
        </ScheduleRowText>
        {!(isMockDrop && !isThisYear(parseJSON(stage.startTime))) && (
          <ScheduleRowText className="mt-0.5 text-secondary" size="medium">
            {showStartAndEndTime && `${t("stageInfo.starts", "Starts")}: `}
            {formatTime(stage.startTime)}
          </ScheduleRowText>
        )}
        {stage.endTime &&
          stage.stageType === "PUBLIC" &&
          showStartAndEndTime && (
            <ScheduleRowText className="mt-0.5 text-secondary" size="medium">
              {`${t("stageInfo.end", "Ends")}: `}
              {formatTime(stage.endTime)}
            </ScheduleRowText>
          )}
      </Block>
      {!editMode && (
        <>
          {(() => {
            if (isPastStage({ endTime: stage.endTime }) || mintedOut) {
              return (
                <Label $userIsEligible={false} size="small" weight="semibold">
                  {t("stageInfo.stageEnded", "Ended")}
                </Label>
              )
            }
            if (isMockDrop) {
              const inProgress = isStageActive({
                startTime: stage.startTime,
                endTime: stage.endTime,
              })
              return (
                <ResponsiveText.Body
                  className={classNames(
                    inProgress ? "text-green-2" : "text-yellow-1",
                    "pt-2 uppercase lg:pt-0",
                  )}
                  color={inProgress ? "success" : "warning"}
                  size="small"
                  weight="semibold"
                >
                  {inProgress
                    ? t("stageInfo.inProgress", "In progress")
                    : t("stageInfo.comingSoon", "Coming soon")}
                </ResponsiveText.Body>
              )
            }
            if (
              showEligibility &&
              (stage.stageType === "PUBLIC" ||
                (stage.stageType === "PRESALE" && stage.allowlistSet))
            ) {
              return (
                <Label
                  $userIsEligible={isEligible || stage.stageType === "PUBLIC"}
                  size="small"
                  weight="semibold"
                >
                  {children ||
                    (isEligible
                      ? t("stageInfo.userEligible", "Eligible")
                      : t("stageInfo.userNoEligible", "Not eligible"))}
                </Label>
              )
            }
            return null
          })()}
        </>
      )}
    </SpaceBetween>
  )
}

const Label = styled(ResponsiveText.Body)<{ $userIsEligible: boolean }>`
  && {
    color: ${props =>
      props.$userIsEligible
        ? props.theme.colors.seaGrass
        : props.theme.colors.darkGray};
  }
`

const ScheduleRowText = styled(ResponsiveText.Body)`
  text-align: left;
  display: block;
`
