/**
 * @generated SignedSource<<443eedb2ba7ab8573e0d29ed9df5a0af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DropStageStageType = "PRESALE" | "PUBLIC" | "%future added value";
export type ReminderSignupModalContent_userDataQuery$variables = {
  address: string;
  collection: string;
};
export type ReminderSignupModalContent_userDataQuery$data = {
  readonly collection: {
    readonly dropv2: {
      readonly stages: ReadonlyArray<{
        readonly endTime: string;
        readonly label: string;
        readonly relayId: string;
        readonly reminderEnabled: boolean;
        readonly stageType: DropStageStageType;
        readonly startTime: string;
        readonly " $fragmentSpreads": FragmentRefs<"StageInfo_data" | "useUpcomingStages_data">;
      }>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ReminderConfirmationModalContent_collection">;
  } | null;
  readonly getAccount: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"ReminderConfirmationModalContent_user">;
    } | null;
  };
};
export type ReminderSignupModalContent_userDataQuery = {
  response: ReminderSignupModalContent_userDataQuery$data;
  variables: ReminderSignupModalContent_userDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "collection"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "address",
    "variableName": "address"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "collection",
    "variableName": "collection"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stageType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reminderEnabled",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReminderSignupModalContent_userDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ReminderConfirmationModalContent_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ReminderConfirmationModalContent_collection"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dropv2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "stages",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StageInfo_data"
                  },
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "useUpcomingStages_data",
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReminderSignupModalContent_userDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AccountType",
        "kind": "LinkedField",
        "name": "getAccount",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailVerified",
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CollectionType",
        "kind": "LinkedField",
        "name": "collection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCategory",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "dropv2",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              (v11/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "stages",
                "plural": true,
                "selections": [
                  (v10/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isDropStageV2Type"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "allowlistSet",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isEligible",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "imageUrl",
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b6c930c79d33c407a3d28c55a34120f",
    "id": null,
    "metadata": {},
    "name": "ReminderSignupModalContent_userDataQuery",
    "operationKind": "query",
    "text": "query ReminderSignupModalContent_userDataQuery(\n  $address: AddressScalar!\n  $collection: CollectionSlug!\n) {\n  getAccount(address: $address) {\n    user {\n      ...ReminderConfirmationModalContent_user\n      id\n    }\n    id\n  }\n  collection(collection: $collection) {\n    ...ReminderConfirmationModalContent_collection\n    dropv2 {\n      __typename\n      stages {\n        __typename\n        stageType\n        relayId\n        label\n        ...StageInfo_data\n        startTime\n        endTime\n        ...useUpcomingStages_data\n        reminderEnabled\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment AddToCalendar_collection on CollectionType {\n  ...collection_url\n  dropv2 {\n    __typename\n    name\n    id\n  }\n}\n\nfragment AddToCalendar_stage on DropStageV2Type {\n  __isDropStageV2Type: __typename\n  label\n  startTime\n  endTime\n}\n\nfragment ReminderConfirmationModalContent_collection on CollectionType {\n  ...AddToCalendar_collection\n  name\n  imageUrl\n  dropv2 {\n    __typename\n    stages {\n      __typename\n      relayId\n      ...AddToCalendar_stage\n      reminderEnabled\n      id\n    }\n    id\n  }\n}\n\nfragment ReminderConfirmationModalContent_user on UserType {\n  email\n  emailVerified\n}\n\nfragment StageInfo_data on DropStageV2Type {\n  __isDropStageV2Type: __typename\n  endTime\n  label\n  startTime\n  allowlistSet\n  stageType\n  isEligible\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n\nfragment useUpcomingStages_data on DropStageV2Type {\n  __isDropStageV2Type: __typename\n  startTime\n  endTime\n}\n"
  }
};
})();

(node as any).hash = "d3821b9ae74144dfd1d79f7d9688b715";

export default node;
