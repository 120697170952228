import React, { Suspense } from "react"
import { RadioGroup, VerticalAligned } from "@opensea/ui-kit"
import { getUnixTime, parseJSON } from "date-fns"
import { FormProvider } from "react-hook-form"
import { useLazyLoadQuery } from "react-relay"
import { RecordSourceProxy, _FragmentRefs } from "relay-runtime"
import styled, { css } from "styled-components"
import { ModalLoader } from "@/components/common/ModalLoader.react"
import { useConnectedAddress } from "@/containers/WalletProvider/WalletProvider.react"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import {
  isPastStage,
  isStageActive,
} from "@/features/primary-drops/containers/PrimaryDropProvider"
import { useUpcomingStages } from "@/features/primary-drops/hooks/useUpcomingStages"
import { useForm } from "@/hooks/useForm"
import { useToasts } from "@/hooks/useToasts"
import { useTranslate } from "@/hooks/useTranslate"
import { trackSetReminder } from "@/lib/analytics/events/primaryDropsV2Events"
import { ReminderSignupModalContent_userDataQuery } from "@/lib/graphql/__generated__/ReminderSignupModalContent_userDataQuery.graphql"
import { ReminderSignupModalContentMutation } from "@/lib/graphql/__generated__/ReminderSignupModalContentMutation.graphql"
import { graphql } from "@/lib/graphql/graphql"
import { useGraphQL } from "@/lib/graphql/GraphQLProvider"
import { media } from "@/styles/styleUtils"
import { ResponsiveButton } from "../../ResponsiveButton"
import { ResponsiveText } from "../../ResponsiveText"
import { StageInfo } from "../../StageInfo"
import { EmailInput } from "../EmailInput"
import { ReminderConfirmationModalContent } from "../ReminderConfirmationModalContent"

type FormData = {
  email: string
  stage: string
}

type ReminderSignupModalContentProps = {
  slug: string
  onClose: () => void
}

const DEFAULT_VALUES: FormData = {
  email: "",
  stage: "",
}

const LazyLoadedReminderSignupModalContent = ({
  slug,
  onClose,
}: ReminderSignupModalContentProps) => {
  const t = useTranslate("drop")
  const { mutate } = useGraphQL()
  const { onNext } = useMultiStepFlowContext()
  const { attempt } = useToasts()
  const connectedAddress = useConnectedAddress()

  const data = useLazyLoadQuery<ReminderSignupModalContent_userDataQuery>(
    graphql`
      query ReminderSignupModalContent_userDataQuery(
        $address: AddressScalar!
        $collection: CollectionSlug!
      ) {
        getAccount(address: $address) {
          user {
            ...ReminderConfirmationModalContent_user
          }
        }
        collection(collection: $collection) {
          ...ReminderConfirmationModalContent_collection
          dropv2 {
            stages {
              stageType
              relayId
              label
              ...StageInfo_data
              startTime
              endTime
              ...useUpcomingStages_data
              reminderEnabled
            }
          }
        }
      }
    `,
    {
      address: connectedAddress || "",
      collection: slug,
    },
    {
      fetchPolicy: "network-only",
    },
  )

  const drop = data.collection?.dropv2
  const upcomingStages = useUpcomingStages(drop?.stages)
  const hasReminderSet = upcomingStages.some(stage => stage.reminderEnabled)

  const defaultValues = { ...DEFAULT_VALUES }
  const publicStageId = upcomingStages.find(
    stage => stage.stageType === "PUBLIC",
  )?.relayId
  defaultValues.stage = publicStageId ?? ""
  const form = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  })

  const { register, handleSubmit, watch, formState } = form

  const stageSelected = watch("stage")

  if (!data.collection?.dropv2) {
    return null
  }

  if (hasReminderSet) {
    onNext(
      <ReminderConfirmationModalContent
        collectionDataKey={data.collection}
        userDataKey={data.getAccount.user}
        onClose={onClose}
      />,
    )
  }

  const submit = handleSubmit(async formData => {
    if (!formData.stage) {
      return
    }
    trackSetReminder({ stage: formData.stage })
    await attempt(async () => {
      const result = await mutate<ReminderSignupModalContentMutation>(
        graphql`
          mutation ReminderSignupModalContentMutation(
            $input: DropReminderMutationInput!
          ) {
            users {
              dropReminder(input: $input) {
                ...ReminderConfirmationModalContent_user
              }
            }
          }
        `,
        {
          input: {
            email: formData.email,
            collection: slug,
            dropStages: [formData.stage],
          },
        },
        {
          updater: (store: RecordSourceProxy) => {
            if (!drop) {
              return
            }
            for (const stage of drop.stages) {
              const storedStage = store.get(stage.relayId)
              storedStage?.setValue(
                formData.stage === stage.relayId,
                "reminderEnabled",
              )
            }
          },
        },
      )

      onNext(
        <ReminderConfirmationModalContent
          collectionDataKey={data.collection}
          userDataKey={result.users.dropReminder}
          onClose={onClose}
        />,
      )
    })
  })

  const orderedStages = [...data.collection.dropv2.stages].sort(
    (a, b) =>
      getUnixTime(parseJSON(a.startTime)) - getUnixTime(parseJSON(b.startTime)),
  )

  return (
    // @ts-expect-error need to use a custom formprovider for inputRef
    <FormProvider {...form}>
      <VerticalAligned asChild>
        <form onSubmit={submit}>
          <ModalBody>
            <Heading>
              {t("reminderSignupModal.title", "Sign up for email reminders")}
            </Heading>
            <EmailInput />
            <StageContainer>
              <RadioGroup
                defaultValue={stageSelected}
                onValueChange={stageRelayId => {
                  const { onChange, name } = register("stage")

                  onChange({
                    target: {
                      name,
                      id: stageRelayId,
                      value: stageRelayId,
                    },
                    type: "radio",
                  })
                }}
              >
                {orderedStages.map(stage => {
                  return (
                    <Flex key={stage.relayId}>
                      <RadioGroup.Item
                        aria-label={t(
                          "reminderSignupModal.inputToEnableReminder",
                          "Enable reminders for {{stageLabel}}",
                          {
                            stageLabel: stage.label,
                          },
                        )}
                        disabled={
                          isPastStage({ endTime: stage.endTime }) ||
                          isStageActive({
                            startTime: stage.startTime,
                            endTime: stage.endTime,
                          })
                        }
                        id={stage.relayId}
                        value={stage.relayId}
                      />
                      <StyledLabel htmlFor={stage.relayId}>
                        <StageInfo showEligibility={false} stageKey={stage} />
                      </StyledLabel>
                    </Flex>
                  )
                })}
              </RadioGroup>
            </StageContainer>
          </ModalBody>
          <Modal.Footer>
            <ResponsiveButton
              className="w-full"
              disabled={!formState.isValid || !stageSelected}
              type="submit"
            >
              {t("reminderSignupModal.submit", "Confirm")}
            </ResponsiveButton>
          </Modal.Footer>
        </form>
      </VerticalAligned>
    </FormProvider>
  )
}

export const ReminderSignupModalContent = (
  props: ReminderSignupModalContentProps,
) => {
  return (
    <Suspense fallback={<ModalLoader />}>
      <LazyLoadedReminderSignupModalContent {...props} />
    </Suspense>
  )
}

const Heading = styled(ResponsiveText.Heading).attrs({
  size: "mediumOnModal",
})`
  display: block;
  text-align: center;
  margin-top: 62px;
  margin-bottom: 24px;
  ${media({
    lg: css`
      padding-left: 24px;
      padding-right: 24px;
    `,
  })}
`

const StageContainer = styled(Block)`
  margin-top: 32px;
`

const ModalBody = styled(Modal.Body)`
  && {
    padding-bottom: 12px;
  }
`

const StyledLabel = styled.label`
  cursor: pointer;
`
