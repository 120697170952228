import React from "react"
import styled from "styled-components"
import { themeVariant } from "@/styles/styleUtils"

type Props = {
  className?: string
  height?: number
  width?: number
}

export const YahooLogo = ({ height = 24, width = 24, ...rest }: Props) => {
  return (
    <StyledSvg
      height={height}
      viewBox="0 0 32 24"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        clipRule="evenodd"
        d="M23.7362 11.9985C23.7362 5.47853 18.5962 0.50016 11.8681 0.50016V0.5C5.13997 0.5 0 5.47853 0 11.9985C0 18.5183 5.13997 23.4988 11.8681 23.4988C18.5962 23.4988 23.7362 18.5185 23.7362 11.9985ZM11.4713 6.86237C11.4148 7.25948 11.3857 7.65644 11.3585 8.05548H11.3583C10.5331 8.1118 9.71003 8.16828 8.88668 8.25388C9.50966 9.01853 10.1326 9.7899 10.7555 10.5613C11.382 11.3371 12.0085 12.113 12.6351 12.882C13.2437 12.3579 13.8528 11.8411 14.4618 11.3243C15.074 10.8048 15.6863 10.2852 16.298 9.75835C16.2427 9.75663 16.1874 9.75492 16.132 9.7532C15.251 9.7259 14.3683 9.69854 13.4876 9.64555V8.47964C15.8921 8.42603 18.272 8.39866 20.6741 8.37104L21.0388 8.36684V9.75851C20.1682 9.84322 19.3245 9.89926 18.4829 9.95515L18.4564 9.95691C16.8098 11.4342 15.1612 12.9092 13.4876 14.3865C13.4727 14.9667 13.4583 15.5541 13.4439 16.141C13.4301 16.7038 13.4163 17.2662 13.402 17.8217C13.458 17.8234 13.514 17.825 13.57 17.8267C14.4793 17.854 15.3886 17.8814 16.298 17.9364V18.9853H7.3822V17.9364C7.86613 17.8934 8.35001 17.8578 8.83387 17.8222C9.31532 17.7868 9.79676 17.7514 10.2782 17.7087V14.4994C8.54604 12.3995 6.84317 10.3267 5.1403 8.25388C4.68463 8.22565 4.23579 8.19001 3.78721 8.1544C3.34096 8.11897 2.89496 8.08357 2.44271 8.05548V6.86237H11.4713Z"
        fillRule="evenodd"
      />
      <path d="M32 1.63879C29.6997 1.52407 27.4283 1.41127 25.1572 1.29639C25.0423 6.72052 24.9295 12.1719 24.8167 17.596C25.8947 17.7089 27.0023 17.8219 28.0803 17.9366C29.3864 12.4854 30.6941 7.06116 32.0002 1.63879H32ZM24.5032 23.4716H28.421V20.2076C27.0023 20.0931 25.5834 19.9801 24.1626 19.8673C24.2754 21.0875 24.3904 22.2806 24.5032 23.4716Z" />
    </StyledSvg>
  )
}

const StyledSvg = styled.svg`
  ${({ theme }) =>
    themeVariant({
      variants: {
        light: {
          fill: theme.colors.blackPearl,
        },
        dark: {
          fill: theme.colors.white,
        },
      },
    })}
`
