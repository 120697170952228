/**
 * @generated SignedSource<<fc6151d83829421da8069000f3700e9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddToCalendar_collection$data = {
  readonly dropv2: {
    readonly name: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"collection_url">;
  readonly " $fragmentType": "AddToCalendar_collection";
};
export type AddToCalendar_collection$key = {
  readonly " $data"?: AddToCalendar_collection$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_collection">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AddToCalendar_collection"
};

(node as any).hash = "c962c2d962413c639a5985369a980d73";

export default node;
