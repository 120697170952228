/**
 * @generated SignedSource<<8bebf3aa685566e0c298eff66d14b6b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddToCalendar_stage$data = {
  readonly endTime: string;
  readonly label: string;
  readonly startTime: string;
  readonly " $fragmentType": "AddToCalendar_stage";
};
export type AddToCalendar_stage$key = {
  readonly " $data"?: AddToCalendar_stage$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_stage">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AddToCalendar_stage"
};

(node as any).hash = "debf714607a0453577dafd6ff0e30c02";

export default node;
