import { getTrackingFn } from "../utils"

type PrimaryDropsEventProps = {
  collectionSlug: string
}

export type MintSource = "mint-module" | "sticky-bar" | "1155_cart"

type MintTransactionParams = PrimaryDropsEventProps & {
  mintSource: MintSource
}

// User can't mint
export const trackPrimaryDropsV2WaitingTime =
  getTrackingFn<PrimaryDropsEventProps>("primary drops v2 -- mint not open")
export const trackPrimaryDropsV2UserNotEligible =
  getTrackingFn<PrimaryDropsEventProps>(
    "primary drops v2 -- user isn't eligible",
  )
export const trackPrimaryDropsV2UserReachTheLimitOfMints =
  getTrackingFn<PrimaryDropsEventProps>(
    "primary drops v2 -- user reached mint limit",
  )
export const trackPrimaryDropsV2CollectionIsFullyMinted =
  getTrackingFn<PrimaryDropsEventProps>(
    "primary drops v2 -- collection is fully minted",
  )

// User can mint
export const trackPrimaryDropsV2ReadyToMint =
  getTrackingFn<PrimaryDropsEventProps>(
    "primary drops v2 -- user is eligible and ready to mint",
  )
export const trackPrimaryDropsV2TransactionStart = getTrackingFn<
  MintTransactionParams & {
    quantity: number
  }
>("primary drops - user clicked on mint button")
export const trackPrimaryDropsV2TransactionPending =
  getTrackingFn<MintTransactionParams>(
    "primary drops v2 -- mint transaction pending",
  )
export const trackPrimaryDropsV2TransactionFailed =
  getTrackingFn<MintTransactionParams>(
    "primary drops v2 -- mint transaction failed",
  )
export const trackPrimaryDropsV2TransactionConfirmed =
  getTrackingFn<MintTransactionParams>(
    "primary drops v2 -- mint transaction confirmed",
  )

// About section
export const trackVideoOnPlay = getTrackingFn<
  PrimaryDropsEventProps & { mediaUrl: string }
>("primary drops v2 -- video on play")
export const trackHistoryTellingLastElementInView =
  getTrackingFn<PrimaryDropsEventProps>(
    "primary drops v2 -- history telling last element in view",
  )

// Reminders
export const trackOpenReminders = getTrackingFn(
  "primary drops v2 -- open reminders",
)
export const trackSetReminder = getTrackingFn<{ stage: string }>(
  "primary drops v2 -- set reminder",
)

// Calendar
export const trackOpenCalendar = getTrackingFn<PrimaryDropsEventProps>(
  "primary drops v2 -- open calendar",
)
export const trackSetCalendar = getTrackingFn<
  PrimaryDropsEventProps & { stage: string }
>("primary drops v2 -- set calendar")

// custom pages
export const trackCustomStageClick = getTrackingFn<{ source: MintSource }>(
  "primary drops v2 -- custom stage cta clicked",
)
