/**
 * @generated SignedSource<<11fde7816ab8162050c198d0a1625736>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddToCalendar_data$data = {
  readonly dropv2: {
    readonly stages: ReadonlyArray<{
      readonly endTime: string;
      readonly relayId: string;
      readonly startTime: string;
      readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_stage" | "StageInfo_data" | "useDropStages">;
    }>;
  } | null;
  readonly slug: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_collection">;
  readonly " $fragmentType": "AddToCalendar_data";
};
export type AddToCalendar_data$key = {
  readonly " $data"?: AddToCalendar_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCalendar_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startTime",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endTime",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddToCalendar_data",
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "AddToCalendar_collection",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "collection_url",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isCategory",
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "dropv2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "dropv2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "stages",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StageInfo_data"
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "AddToCalendar_stage",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            {
              "kind": "InlineDataFragmentSpread",
              "name": "useDropStages",
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            },
            (v2/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "relayId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CollectionType",
  "abstractKey": null
};
})();

(node as any).hash = "8b1b24ce465780a17f85749ad103ce3b";

export default node;
