import React from "react"
import { useWalletModal } from "@/containers/WalletModalProvider.react"
import { useWallet } from "@/containers/WalletProvider/WalletProvider.react"
import { UncontrolledModalProps } from "@/design-system/Modal"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import { trackOpenReminders } from "@/lib/analytics/events/primaryDropsV2Events"
import { ReminderSignupModalContent } from "./ReminderSignupModalContent"

type ReminderSignupProps = {
  slug: string
  trigger: UncontrolledModalProps["trigger"]
}

export const ReminderSignup = ({ slug, trigger }: ReminderSignupProps) => {
  const { isAuthenticated, login } = useWallet()
  const { startWalletModalAuthFlow } = useWalletModal()
  const getClickHandler = (onClick: () => unknown) => async () => {
    if (!isAuthenticated) {
      startWalletModalAuthFlow(async () => {
        await login()
        trackOpenReminders()
        onClick()
      })
    } else {
      trackOpenReminders()
      onClick()
    }
  }

  return (
    <MultiStepModal
      returnFocusOnClose={false}
      size="medium"
      trigger={open => trigger(getClickHandler(open))}
    >
      {onClose => <ReminderSignupModalContent slug={slug} onClose={onClose} />}
    </MultiStepModal>
  )
}
